import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import hero from '../../images/issues/invest-public-health-healthcare/invest-public-health-healthcare-hero.webp'
import accomplishment1 from '../../images/issues/invest-public-health-healthcare/invest-public-health-healthcare-accomplishment-1.webp'
import accomplishment2 from '../../images/issues/invest-public-health-healthcare/invest-public-health-healthcare-accomplishment-2.webp'
import accomplishment3A from '../../images/issues/invest-public-health-healthcare/invest-public-health-healthcare-accomplishment-3a.webp'
import accomplishment3B from '../../images/issues/invest-public-health-healthcare/invest-public-health-healthcare-accomplishment-3b.webp'
import accomplishment3C from '../../images/issues/invest-public-health-healthcare/invest-public-health-healthcare-accomplishment-3c.webp'
import accomplishment3D from '../../images/issues/invest-public-health-healthcare/invest-public-health-healthcare-accomplishment-3d.webp'
import accomplishment3E from '../../images/issues/invest-public-health-healthcare/invest-public-health-healthcare-accomplishment-3e.webp'
import accomplishment4 from '../../images/issues/invest-public-health-healthcare/invest-public-health-healthcare-accomplishment-4.webp'

const IssuesInvestPublicHealthHealthcarePage = ({ location }) => (
  <Layout>
    <Seo location={location} title="Issues - Invest in Public Health & Healthcare" />
    <div className="bg-white">
      <div className="max-w-7xl mx-auto px-4 xl:px-0">
        <div>
          <img
              className="w-full max-h-96 object-cover object-pos-0-40" // TODO: figure out aspect ratio -- trying to set 2:1
              src={hero}
              alt=""
            />
        </div>
        <div className="pt-10 max-w-5xl mx-auto">
          <h1 className="font-bold text-6xl">Invest in Public Health & Healthcare</h1>
          <p className="text-2xl leading-relaxed">
            Rhode Island offers multiple highly regarded hospitals and medical centers at the forefront of the healthcare industry and innovation. However, world-class care does not adequately extend to every individual, family, or community. Already unbalanced and struggling, the COVID-19 pandemic has exacerbated the racial and socioeconomic gaps in healthcare access. Communities affected by gun violence, unsafe or inadequate residences, food insecurity, and higher rates of substance abuse disorder fail to get the help they need from top-of-the-line medical institutions across the state. One of John's primary goals is to expand access to all communities and ensure proper healthcare for families who need and deserve the benefits of our public health services.
          </p>
          <p className="text-2xl leading-relaxed">
            John supports the <strong><a href="https://www.sanders.senate.gov/wp-content/uploads/Exec-Summary_Medicare-for-All-2023.pdf" target="_blank">Medicare for All Act</a></strong>, which would establish universal health care through a single-payer system. He would also support <strong>a national paid sick days law or the Healthy Families Act of 2023</strong>.
          </p>
        </div>
        <div className="max-w-5xl mx-auto pt-10">
          <span className="text-base tracking-widest uppercase">John's Record</span> {/* Is "span" the right tag for eyebrows?  Maybe? */}
          <h2 className="font-bold text-5xl pb-10">What We've Accomplished Together</h2>
          {/*TODO: L side - bullet point, R side - image - TEXT John when I'm working on this, Sanctuary, John visiting Boston - Mass & Cass, etc.*/}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
            <div>
              <h3 className="font-bold text-xl">Facilitated COVID-19 Information</h3>
              <p>• Sent daily newsletters in the height of the pandemic to keep folks informed about the Governor's executive orders and other pertinent information regarding the pandemic.</p>
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment1}
                alt=""
              />
            </div>
            <div>
              <h3 className="font-bold text-xl">Got Shots In Arms</h3>
              <p>• Did significant outreach to ensure that the Ward 1 community could get vaccinated against COVID-19 as quickly as possible.</p>
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment2}
                alt=""
              />
              <aside className="text-xs">Suzanne Kreiter / The Boston Globe via Getty Images</aside>
            </div>
            <div>
              <h3 className="font-bold text-xl">Helped Address Food Insecurity &amp; Access to Fresh Produce</h3>
              <p>• Launched robust community partnership with the Friends of Cabral Park, Providence Partnership for Parks spearheaded by Helene Miller, and iProv Fellow 
                Eleanor Pereboom to provide a safe and reliable food source for the Fox Point community. The program is especially designed for senior citizens who live adjacent to the Park, and the partnership aims to develop community-driven food justice in Fox Point.</p>
              <p>• Once the pandemic began, the seniors' need for food access came into sharp focus and the group had the idea to use the park and their Park Friends Group as platforms to address their food insecurity.  Seniors were subsidized and were able to use their SNAP benefits to participate in this program.</p>
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment3A}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment3B}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment3C}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment3D}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment3E}
                alt=""
              />
            </div>
            <div>
              <h3 className="font-bold text-xl">Launched "Neighbors Helping Neighbors" Initiative In The Height Of The COVID-19 Pandemic</h3>
              <p>• With the help of Ward 1 resident Dr. Julia Carlson, launched a "Neighbors Helping Neighbors" initiative of over 150+ volunteers who helped with transportation, direct assistance, delivery of prescriptions and groceries, someone to talk to in the height of the pandemic.</p>
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment4}
                alt=""
              />
              <aside className="text-xs">Lane Turner / The Boston Globe</aside>
            </div>
          </div>
        </div>
        <div className="max-w-5xl mx-auto pt-10 pb-10">
          <span className="text-base tracking-widest uppercase">Our Policy Platform Moving Forward</span> {/* Is "span" the right tag for eyebrows?  Maybe? */}
          <h2 className="font-bold text-5xl">The Work We Still Have To Do</h2>

          <p className="text-xl">Rhode Island offers multiple highly regarded hospitals and medical centers at the forefront of the healthcare industry and innovation. However, world-class care does not adequately extend to every individual, family, or community. Already unbalanced and struggling, the COVID-19 pandemic has exacerbated the racial and socioeconomic gaps in healthcare access. Communities affected by gun violence, unsafe or inadequate residences, food insecurity, and higher rates of substance abuse disorder fail to get the help they need from top-of-the-line medical institutions across the state. One of John's primary goals is to expand access to all communities and ensure proper healthcare for families who need and deserve the benefits of our public health services.</p>
        </div>
      </div>
    </div>
  </Layout>
)

export default IssuesInvestPublicHealthHealthcarePage
